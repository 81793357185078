
/* eslint-disable */
// Autogenerated
// -------------
// gulpfile.ts/wasm.ts --> generateBuildInfo

export const Build = {
    version: "0.73.5 (44a73d83c45012932af027c50ffa1dff)",
    buildSeed: 1650617957499,
    "wdosbox.shared.wasm": {
        "size": 1489555,
        "gzSize": 506591
    },
    "wdosbox.shared.js": {
        "size": 144083,
        "gzSize": 36451
    },
    "wdosbox.wasm": {
        "size": 1469621,
        "gzSize": 502791
    },
    "wdosbox.js": {
        "size": 124356,
        "gzSize": 32460
    },
    "wlibzip.wasm": {
        "size": 113086,
        "gzSize": 54568
    },
    "wlibzip.js": {
        "size": 77196,
        "gzSize": 20137
    }
,
};
